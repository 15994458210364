<template>
  <v-container fluid>
  </v-container>
</template>

<script>

import axios from 'axios'
import jsPDF from 'jspdf'

export default {
  data: () => ({
    generalBalance: null,
    desserts: [],
    ingresosToday: null,
    margenSubtitle: null,
    egresosToday: null,
    saldoToday: null,
    listCashOperation: [],
    reportTitle: null,
    employee: null,
    paymentDate: null,
    client: null,
    location: null,
    description: null,
    amount: null,
    amountExpiringInterest: null,
    amountVisitInterest: null,
    total: null,
    saldo: null,

  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },

  async mounted() {
    await this.getReport()
    await this.generarReporte()
    if (this.$route.params.ruta === 'Recibos') {
      this.$router.push({ name: 'abmdocument' })
    } else {
      this.$router.push({ name: 'creditconfirm' })
    }
  },
  methods: {
    calcularPorcentajeInteres() {
      return Math.round((this.amountExpiringInterest) * 100 / parseFloat(this.amount))
    },

    async generarReporte() {
    //   const doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "px",
    //     //format: [4, 2],
    //     format: "A4",
    //     margin: { top: 20 },
    //   });
      const doc = new jsPDF('p', 'pt')
      doc.setFont('Arial')
      const logo = require('../../assets/images/logos/efectivo-ya-png.png')

      const imgLogo = new Image()
      imgLogo.src = logo
      doc.addImage(imgLogo, 'PNG', 40, 40, 200, 80)
      doc.setFontSize(50)
      const titulo = 'Recibo'
      doc.text(titulo, doc.internal.pageSize.width * 0.4, 190, {
        aling: 'center',

      })

      doc.line(20, 140, 560, 140)

      doc.text(this.$route.params.documentnumber.toString(), doc.internal.pageSize.width * 0.3, 240, {
        aling: 'center',
      })

      // const lineaInicial = 70;

      //   const lineaInicialSombreado = 80;

      const espacioEntreLineas = 60

      const fechaCobro = this.paymentDate

      // doc.setFillColor(240, 240, 240)
      // doc.rect(
      //   doc.internal.pageSize.width * 0.05,
      //   5,
      //   900,
      //   13,
      //   'F',
      // )
      doc.setFontSize(38)
      doc.text(
        fechaCobro,
        doc.internal.pageSize.width * 0.5,
        35 + espacioEntreLineas * 1,
        {
          aling: 'center',

        },
      )

      // const cobrador = `Cobrador: ${this.employee}`
      // const client = `Cliente: ${this.client}`
      // const location = `Dirección: ${this.location}`
      // const description = `Detalle: ${this.description}`
      doc.setFontSize(50)
      const amount = `Monto: $ ${this.amount}`
      const amountExpiringInterest = `Interés ${this.calcularPorcentajeInteres()}% por Venc.: $  ${this.amountExpiringInterest}`
      const amountVisitInterest = `Rec. por visita: $ ${this.amountVisitInterest}`
      const total = `Total: $ ${this.total}`
      const saldo = `Saldo del crédito: $ ${this.saldo}`
      doc.setFontSize(30)
      doc.text(
        'Cobrador',
        doc.internal.pageSize.width * 0.055,
        250 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.line(doc.internal.pageSize.width * 0.055, 320, 200, 320)
      doc.setFontSize(37)
      doc.text(
        this.employee,
        doc.internal.pageSize.width * 0.055,
        302 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.setFontSize(30)
      doc.text(
        'Cliente',
        doc.internal.pageSize.width * 0.055,
        350 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.line(doc.internal.pageSize.width * 0.05, 420, 200, 420)
      doc.setFontSize(37)
      doc.text(
        this.client,
        doc.internal.pageSize.width * 0.055,
        402 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.setFontSize(30)
      doc.text(
        'Detalle',
        doc.internal.pageSize.width * 0.055,
        450 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )
      doc.line(doc.internal.pageSize.width * 0.05, 520, 200, 520)
      doc.setFontSize(37)
      doc.text(
        this.description,
        doc.internal.pageSize.width * 0.055,
        502 + espacioEntreLineas * 1,
        {
          aling: 'right',

        },
      )

      doc.text(
        amount,
        doc.internal.pageSize.width * 0.055,
        570 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )
      doc.text(
        amountExpiringInterest,
        doc.internal.pageSize.width * 0.055,
        620 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )
      doc.text(
        amountVisitInterest,
        doc.internal.pageSize.width * 0.055,
        670 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )

      doc.text(
        total,
        doc.internal.pageSize.width * 0.055,
        720 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )
      doc.line(doc.internal.pageSize.width * 0.055, 795, 400, 795)
      doc.setFontSize(30)
      doc.text(
        saldo,
        doc.internal.pageSize.width * 0.055,
        770 + espacioEntreLineas * 1,
        {
          aling: 'left',

        },
      )

      doc.save(`Recibo ${this.$route.params.documentnumber}.pdf`, false)
    },

    async getReport() {
      this.url = `${this.$store.getters.urlBase}/api/Report/paymentDocument`
      const options = {
        headers: {
          'content-type': 'application/json',
        },
        params: {
          paymentNumber: this.$route.params.documentnumber, // this.moment().format('YYYY-MM-DD'),

        },

        url: this.url,
      }
      await axios
        .get(this.url, options)
      // eslint-disable-next-line no-return-assign
        .then(response => {
          this.employee = response.data.employee
          this.paymentDate = response.data.paymentDate
          this.client = response.data.client
          this.location = response.data.location
          this.description = response.data.description
          this.amount = response.data.amount
          this.amountExpiringInterest = response.data.amountExpiringInterest
          this.amountVisitInterest = response.data.amountVisitInterest
          this.total = response.data.total
          this.saldo = response.data.amountLeftToPay
        })
        .catch(error => {

        })
    },

  },
}
</script>
